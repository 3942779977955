import { createFeature, createReducer, on } from '@ngrx/store';

import { CrmLead } from '@bp/frontend/domains/crm/leads/models';
import { BpError } from '@bp/frontend/models/core';

import { saveCrmLeadKeptInStore, updateCrmLeadInStore } from './current-crm-lead.actions';
import { saveCrmLeadFailure, saveCrmLeadSuccess } from './current-crm-lead-api.actions';

export interface IState {

	lead: CrmLead | null;

	pending: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	lead: null,
	pending: false,
	error: null,
};

export const currentCrmLeadFeature = createFeature({
	name: '[domain]:current-crm-lead',
	reducer: createReducer(
		initialState,

		on(updateCrmLeadInStore, (state, { lead }): IState => ({
			...state,
			lead,
		})),

		on(saveCrmLeadKeptInStore, (state): IState => ({
			...state,
			pending: !!state.lead?.canBeSaved(),
			error: null,
		})),

		on(
			saveCrmLeadSuccess,
			saveCrmLeadFailure,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			saveCrmLeadFailure,
			(state, { error }): IState => ({
				...state,
				error,
			}),
		),
	),
});
