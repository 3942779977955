import { createAction, props } from '@ngrx/store';

import { CrmLead } from '@bp/frontend/domains/crm/leads/models';
import { createVerboseAction } from '@bp/frontend/ngrx';

import { ACTIONS_SCOPE } from './current-crm-lead-api.actions';

export const updateCrmLeadInStore = createAction(
	`${ ACTIONS_SCOPE } Update Crm Lead In Store`,
	props<{ lead: CrmLead }>(),
);
export const trySaveCrmLeadKeptInStore = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm Lead Kept In Store`,
	props<{ lead: CrmLead }>(),
);

export const saveCrmLeadKeptInStore = createAction(
	`${ ACTIONS_SCOPE } Save Crm Lead Kept In Store`,
	props<{ lead: CrmLead }>(),
);

export const trySaveCrmLeadAnalytics = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm Lead Analytics`,
	props<{ lead: CrmLead }>(),
);

export const saveCrmLeadAnalytics = createAction(
	`${ ACTIONS_SCOPE } Save Crm Lead Analytics`,
	props<{ lead: CrmLead }>(),
);

export const convertCrmLeadToCrmUser = createAction(
	`${ ACTIONS_SCOPE } Convert Crm Lead To Crm User`,
);
