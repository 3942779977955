import { Enumeration } from '@bp/shared/models/core/enum';

export class BridgerPspIndustry extends Enumeration {

	static eCommerce = new BridgerPspIndustry('eCommerce');

	static financialServices = new BridgerPspIndustry('Financial Services');

	static gaming = new BridgerPspIndustry();

	static iGaming = new BridgerPspIndustry('iGaming');

	static marketplace = new BridgerPspIndustry();

	static travel = new BridgerPspIndustry();

	static cbdOrHempProducts = new BridgerPspIndustry('Hemp Products / CBD');

	static tobaccoOrEcigarettes = new BridgerPspIndustry('Tobacco / eCigarettes');

	static marketingServices = new BridgerPspIndustry();

	static charity = new BridgerPspIndustry();

	static digitalGoods = new BridgerPspIndustry();

	static pharmaceutical = new BridgerPspIndustry();

	static shippingServices = new BridgerPspIndustry();

	static cryptoExchange = new BridgerPspIndustry();

	static education = new BridgerPspIndustry();

	static gambling = new BridgerPspIndustry();

	static retail = new BridgerPspIndustry();

	static saas = new BridgerPspIndustry('SAAS');

}
