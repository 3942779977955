import { AliasFor, Default, DTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';
import {
	SubscriptionPlanChargePeriod, SubscriptionFeaturesLimitsCounters, SubscriptionPlanType
} from '@bp/shared/domains/subscription-plans/core';
import { FiatCurrency } from '@bp/shared/models/currencies';

export class OrganizationSubscription extends MetadataEntity {

	@AliasFor('subscriptionId', { serializeAliasSourceProperty: false })
	subscriptionPlanId!: string;

	@Mapper(SubscriptionPlanChargePeriod)
	chargePeriod!: SubscriptionPlanChargePeriod;

	@Mapper(FiatCurrency)
	currency!: FiatCurrency;

	@Mapper(SubscriptionFeaturesLimitsCounters)
	limitsCounters!: SubscriptionFeaturesLimitsCounters;

	@Mapper(SubscriptionPlanType)
	subscriptionPlanType?: SubscriptionPlanType;

	/**
	 * if no credit card collected we assume there is no stripe subscription at this point.
	 */
	@Default(null)
	pspSubscriptionId!: string | null;

	get hasBillingPortal(): boolean {
		return !!this.pspSubscriptionId;
	}

	constructor(dto?: DTO<OrganizationSubscription>) {
		super(dto);
	}

}
