import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload, ResultActionPayload } from '@bp/frontend/models/common';
import { WriteCrmLeadApiRequestResult, IntercomContact, CrmLead } from '@bp/frontend/domains/crm/leads/models';

export const ACTIONS_SCOPE = '[Domain][Current Crm Lead]';

const scope = `[API]${ ACTIONS_SCOPE }`;

type SaveCrmLeadRequest = { request: CrmLead };

export const saveCrmLeadSuccess = createAction(
	`${ scope } Save Crm Lead Success`,
	props<ResultActionPayload<WriteCrmLeadApiRequestResult> & SaveCrmLeadRequest>(),
);

export const saveCrmLeadFailure = createAction(
	`${ scope } Save Crm Lead Failure`,
	props<ErrorActionPayload & SaveCrmLeadRequest>(),
);

export const saveCrmLeadAnalyticsSuccess = createAction(
	`${ scope } Save Crm Lead Analytics Success`,
	props<ResultActionPayload<WriteCrmLeadApiRequestResult> & SaveCrmLeadRequest>(),
);

export const saveCrmLeadAnalyticsFailure = createAction(
	`${ scope } Save Crm Lead Analytics Failure`,
	props<ErrorActionPayload & SaveCrmLeadRequest>(),
);

export const convertCrmLeadToCrmUserSuccess = createAction(
	`${ scope } Convert Crm Lead To Crm User Success`,
);

export const convertCrmLeadToCrmUserFailure = createAction(
	`${ scope } Convert Crm Lead To Crm User Failure`,
	props<ErrorActionPayload>(),
);

export const intercomContactGotCreatedOrUpdated = createAction(
	`${ ACTIONS_SCOPE } Intercom Contact Got Created Or Updated`,
	props<{ intercomContact: IntercomContact }>(),
);
