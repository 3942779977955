import { lastValueFrom, first, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { DTO } from '@bp/shared/models/metadata';

import { CrmLead } from '@bp/frontend/domains/crm/leads/models';
import { takePresent } from '@bp/frontend/rxjs';
import { CurrentCrmUserFacade } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { BpError } from '@bp/frontend/models/core';

import { convertCrmLeadToCrmUser, trySaveCrmLeadKeptInStore, updateCrmLeadInStore } from './current-crm-lead.actions';
import { currentCrmLeadFeature, IState } from './current-crm-lead.feature';
import { saveCrmLeadFailure, saveCrmLeadSuccess } from './current-crm-lead-api.actions';

/**
 * State management for the current crm lead stored in firebase
 */
@Injectable({
	providedIn: 'root',
})
export class CurrentCrmLeadFacade {

	private readonly __store$ = inject<Store<IState>>(Store);

	private readonly __actions$ = inject(Actions);

	private readonly __currentCrmUserFacade = inject(CurrentCrmUserFacade);

	private readonly __currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	readonly lead$ = this.__store$.select(currentCrmLeadFeature.selectLead);

	readonly error$: Observable<BpError | null> = this.__store$.select(currentCrmLeadFeature.selectError);

	readonly leadPresent$ = this.lead$.pipe(takePresent);

	lead: CrmLead | null = null;

	readonly trySaveCrmLeadKeptInStore$ = this.__actions$.pipe(ofType(trySaveCrmLeadKeptInStore));

	readonly saveCrmLeadSuccess$ = this.__actions$.pipe(ofType(saveCrmLeadSuccess));

	readonly saveCrmLeadFailure$ = this.__actions$.pipe(ofType(saveCrmLeadFailure));

	constructor() {
		this.__keepCrmLeadPropertyUpdated();
	}

	factory = (dto?: DTO<CrmLead>): CrmLead => new CrmLead(dto);

	async updateLeadInStore(partialCrmLead: DTO<CrmLead>): Promise<void> {
		const updatedLead = this.factory({
			...this.lead,
			...partialCrmLead,
			analytics: {
				...this.lead?.analytics,
				...partialCrmLead.analytics,
			},
		});

		this.__store$.dispatch(updateCrmLeadInStore({ lead: updatedLead }));

		await lastValueFrom(this.lead$.pipe(
			first(lead => lead === updatedLead),
		));
	}

	resetLeadInStore(): void {
		this.__store$.dispatch(updateCrmLeadInStore({
			lead: this.factory({
				crmOwnerEmail: this.lead?.crmOwnerEmail,
				analytics: this.lead?.analytics,
			}),
		}));
	}

	saveLeadKeptInStore(): void {
		this.__store$.dispatch(trySaveCrmLeadKeptInStore({ lead: this.lead! }));
	}

	async updateAndSaveLeadKeptInStore(partialCrmLead: DTO<CrmLead>): Promise<void> {
		await this.updateLeadInStore(partialCrmLead);

		this.saveLeadKeptInStore();
	}

	async convertToCrmUser({ userId, organizationId }: { userId: string; organizationId: string }): Promise<void> {
		await this.updateLeadInStore({
			userId,
			organizationId,
		});

		this.__store$.dispatch(convertCrmLeadToCrmUser());

		await this.__currentCrmOrganizationFacade.updateAndSaveOrganizationKeptInStore({
			id: organizationId,
		});

		await this.__currentCrmUserFacade.updateAndSaveUserKeptInStore({ id: userId, organizationId });
	}

	private __keepCrmLeadPropertyUpdated(): void {
		this.lead$.subscribe(lead => (this.lead = lead));
	}

}
