import { Validators } from '@bp/shared/features/validation/models';
import {
	MapFromDTO, DTO, Control, FieldControlType, Mapper, Default, lowerCase
} from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { CrmUser } from '@bp/frontend/domains/crm/users/models';

import { CrmLeadType } from './crm-lead-type';

export type CrmLeadKeys = NonFunctionPropertyNames<CrmLead>;

export type CrmLeadDTO = DTO<CrmLead>;

export class CrmLead extends CrmUser {

	static {
		this._initClassMetadata();
	}

	@Mapper(CrmLeadType)
	@Default(CrmLeadType.customer)
	type!: CrmLeadType;

	get isCustomer(): boolean {
		return this.type === CrmLeadType.customer;
	}

	get isPartner(): boolean {
		return this.type === CrmLeadType.partner;
	}

	@Mapper(lowerCase)
	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'url',
			placeholder: 'www.yourcompany.com',
			validator: Validators.urlWithOptionalProtocol,
			typeControlOptions: {
				inputmode: 'url',
			},
		},
	)
	companyWebsite?: string;

	@Control(
		FieldControlType.input,
		{
			nativeAutocomplete: 'organization',
			validator: Validators.compose([
				Validators.xssSafe,
				Validators.noUrl,
			])!,
		},
	)
	companyName?: string;

	@MapFromDTO()
	oauthIdToken?: string;

	@MapFromDTO()
	oauthProvider?: string;

	@MapFromDTO()
	crmOwnerEmail?: string;

	@MapFromDTO()
	isIntercomLead?: boolean;

	@MapFromDTO()
	intercomCrmOwnerEmail?: string;

	@MapFromDTO()
	userId?: string;

	@MapFromDTO()
	bpLeadId?: string;

	override readonly isOwner: boolean = true;

	constructor(dto?: CrmLeadDTO) {
		super(dto);

		this.id = this.email ?? null;
	}

	isConverted(): boolean {
		return !!this.userId;
	}

	canBeSaved(): boolean {
		return !!this.email && !this.isConverted();
	}

}
