import { Default, DTO, Mapper, numberMapper } from '@bp/shared/models/metadata';

import { SubscriptionPlanFeaturesLimits } from './subscription-plan-features-limits';

export class SubscriptionFeaturesLimitsCounters extends SubscriptionPlanFeaturesLimits {

	@Mapper(numberMapper)
	@Default(null)
	override transactions!: number | null;

	constructor(dto?: DTO<SubscriptionFeaturesLimitsCounters>) {
		super(dto);
	}

}
