import { createAction } from '@ngrx/store';

export const VERBOSE_PROPERTY_KEY = 'verbose';

const defineVerboseProperty = {
	apply(target: typeof createAction, thisArgument: unknown, argumentsList: unknown[]): any {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const result = Reflect.apply(target, thisArgument, argumentsList);

		Object.defineProperty(result, VERBOSE_PROPERTY_KEY, { value: true });

		return new Proxy(result, defineVerboseProperty);
	},
};

export const createVerboseAction: typeof createAction = new Proxy(createAction, defineVerboseProperty);
